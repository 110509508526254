





















































































































































@import '~variables'

.dashboard
  position relative
  padding-top 15px
  .lightbulb-enable
    color #CCCA14
    &:hover
      cursor pointer
      transition all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)
  .lightbulb-disable
    color $grey
  .select-field
    position absolute
    top 8px
    left 10px
    font-weight 700
    font-size $fs-body
  .group-by-button
    margin 15px 0 0 10px
    font-size $fs-body
  .data
    padding 20px
    .data__row
      display flex
      flex-direction row
      justify-content space-evenly
    .data__label
      font-weight 700
      font-size $fs-h3
    .data__value
      font-size $fs-h3
  .graph-card
    margin-bottom $border-thick
    max-width 400px
    width 100%
    &:last-child
      margin-bottom 0
  .graph-card
    max-width 2000px
