@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.dashboard {
  position: relative;
  padding-top: 15px;
}
.dashboard .lightbulb-enable {
  color: #ccca14;
}
.dashboard .lightbulb-enable:hover {
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.dashboard .lightbulb-disable {
  color: #b4b2a9;
}
.dashboard .select-field {
  position: absolute;
  top: 8px;
  left: 10px;
  font-weight: 700;
  font-size: 1rem;
}
.dashboard .group-by-button {
  margin: 15px 0 0 10px;
  font-size: 1rem;
}
.dashboard .data {
  padding: 20px;
}
.dashboard .data .data__row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.dashboard .data .data__label {
  font-weight: 700;
  font-size: 1.1rem;
}
.dashboard .data .data__value {
  font-size: 1.1rem;
}
.dashboard .graph-card {
  margin-bottom: 4px;
  max-width: 400px;
  width: 100%;
}
.dashboard .graph-card:last-child {
  margin-bottom: 0;
}
.dashboard .graph-card {
  max-width: 2000px;
}
/*# sourceMappingURL=src/oapps/philipshue_YEeQ7Be1L9/components/dashboard.css.map */